import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'
import { checkout } from '~/store/checkout'
import { cart } from '~/store/cart'
export function getTargetDispatchType (path: string) {
    return path.endsWith(('shop')) ? DispatchTypeOrderBuddy.DELIVER : DispatchTypeOrderBuddy.PICK_UP
}

export function shouldResetCart (dispatchType: DispatchTypeOrderBuddy) {
    return checkout().input.dispatchType !== dispatchType && cart().hasCartProducts
}

async function handleDispatchTypeChange (dispatchType: DispatchTypeOrderBuddy, callback?: () => Promise<void>) {
    if (callback) {
        await callback()
    }
    const route = useRoute()
    if (!route.path.includes('checkout')) {
        await navigateTo(getDispatchTypeUrl(dispatchType))
    } else {
        await checkout().setDispatchType(dispatchType, true)
    }
}

export async function navigateToDispatchType (dispatchType: DispatchTypeOrderBuddy, callback?: () => Promise<void>) {
    if (shouldResetCart(dispatchType)) {
        // eslint-disable-next-line no-alert
        if (confirm('You have items in your cart. Changing the dispatch type will reset your cart.')) { // TODO: replace with modal
            cart().resetCart()
            await handleDispatchTypeChange(dispatchType, callback)
        } else {
            return false
        }
    } else {
        await handleDispatchTypeChange(dispatchType, callback)
    }
}

export function getDispatchTypeUrl (dispatchType: DispatchTypeOrderBuddy) {
    const localePath = useLocalePath()
    if (dispatchType === DispatchTypeOrderBuddy.DELIVER) {
        return localePath('/shop')
    } else {
        return localePath('/shop/pickup')
    }
}
